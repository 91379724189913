.gallery-card {
    width: auto;
    height: 100%;
    background-color: rgb(252, 252, 252);
    border-radius: 0.6rem;
    overflow: hidden;
    padding: 1.2rem;
    padding-bottom: 0.8rem;
    box-shadow: 0 0.24rem 0.48rem 0.12rem rgba(0, 0, 0, 0.15);
    transition: 0.4s;
    text-align: center;
    margin: 1rem;
  }
  
  .gallery-card:hover {
    box-shadow: 0 0.48rem 0.96rem 0.24rem rgba(0, 0, 0, 0.2);
  }
  
  .gallery-card-title {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 1.3rem;
    color: rgba(0, 0, 0, 0.95);
    margin: 0;
    padding: 0;
  }
  
  .gallery-card-time {
    font-family: "Roboto";
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.75);
    margin: 0;
    padding: 0;
    padding-top: 1rem;
    text-align: right;
  }
  
  .gallery-card-image {
    display: block;
    height: 18rem;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: auto;
    margin-bottom: 1.5rem;
  }
  
  @import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&family=Merriweather&family=Nunito&family=Nunito+Sans:wght@700&family=Roboto:wght@100&display=swap");
  
  .give_merry {
    font-family: "Merriweather", serif;
  }
  
  .quick-li{
    text-decoration: none !important;
  }
  
  .quick-links li {
    border-bottom: 1px solid #555;
    display: inline;
    margin : 0% 2.5%;
    color : #fff;
    font-size : 1.2rem;
  }
  
  .quick-links{
    padding-top:1%;
    padding-bottom: 1%;
  }
  
  .font-change-applicable{
    font-size: medium;
  }
  .tcolor{
    color: #fff !important;
  }